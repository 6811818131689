import React from 'react';
import Footer from './Footer';
import {graphql, Link, useStaticQuery} from "gatsby";
import {slide as Menu} from 'react-burger-menu'

const Layout = ({ children }) => {
  const {strapiCompanyData, file, allStrapiExternalLink} = useStaticQuery(query);
  return (
    <>
        <header>
        <div className="fixed z-10 py-5 px-5 lg:hidden w-full">
            <div className="nav-wrapper h-24 lg:container mx-auto rounded flex uppercase font-bold px-5">
                <div><Link className="block h-full w-full flex justify-center items-center" to="/"><img src={file.childImageSharp.fixed.src} alt="Ducati Logo" /></Link></div>
            </div>
        </div>
        <Menu className="uppercase font-bold">
            <Link id="neuigkeiten" to="/neuigkeiten">Neuigkeiten</Link>
            <Link id="fahrzeugbestand" to="/fahrzeugbestand">Fahrzeugbestand</Link>
            {allStrapiExternalLink.nodes.filter(link => link.position === 'left').map(link => (
                <a id={link.title} href={link.url} title={link.alt} target="_blank" rel="external nofollow noreferrer">{link.title}</a>
            ))}
            {allStrapiExternalLink.nodes.filter(link => link.position === 'right').map(link => (
                <a id={link.title} href={link.url} title={link.alt} target="_blank" rel="external nofollow noreferrer">{link.title}</a>
            ))}
            <Link id="datenschutz" to="/datenschutz">Datenschutz</Link>
            <Link id="impressum" to="/impressum">Impressum</Link>
        </Menu>
        <h1 className="px-3 md:px-0 text-center font-bold pb-5 bg-light pt-40 sm:pt-5 text-4xl sm:text-5xl">{strapiCompanyData.name}</h1>
        <div className="w-full bg-light z-10 pb-5 px-5 md:px-0 hidden lg:block">
            <div className="nav-wrapper h-24 container mx-auto rounded grid grid-cols-12 uppercase font-bold px-2">
                <div className="menu-left col-span-5 flex items-center justify-around text-center">
                    <Link id="neuigkeiten" to="/neuigkeiten"><span className="hidden xl:inline">Neuigkeiten</span><span className="xl:hidden">News</span></Link>
                    <Link id="fahrzeugbestand" to="/fahrzeugbestand"><span className="hidden xl:inline">Fahrzeugbestand</span><span className="xl:hidden">Fahrzeuge</span></Link>
                    {allStrapiExternalLink.nodes.filter(link => link.position === 'left').map(link => (
                        <a href={link.url} title={link.alt} target="_blank" rel="external nofollow noreferrer">{link.title}</a>
                    ))}
                </div>
                <div className="menu-center col-span-2"><Link className="block h-full w-full flex justify-center items-center" to="/"><img src={file.childImageSharp.fixed.src} alt="Ducati Logo" /></Link></div>
                <div className="menu-right col-span-5 flex items-center justify-around text-center">
                    {allStrapiExternalLink.nodes.filter(link => link.position === 'right').map(link => (
                        <a href={link.url} title={link.alt} target="_blank" rel="external nofollow noreferrer">{link.title}</a>
                    ))}
                    <Link to="/datenschutz">Datenschutz</Link>
                    <Link to="/impressum">Impressum</Link>
                </div>
            </div>
        </div>
        </header>
        <main className="pb-10 bg-light"><div className="container mx-auto">{children}</div></main>
      <Footer />
    </>
  );
};

const query = graphql`
query Layout {
  strapiCompanyData {
    name
  }
  file(relativePath: {eq: "logo-full.png"}) {
    childImageSharp {
      fixed(height: 80) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  allStrapiExternalLink {
    nodes {
      title
      alt
      url
      position
    }
  }
}
`;

export default Layout;
