import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';

const Footer = () => {
  const {strapiCompanyData} = useStaticQuery(query);
  return (
  <footer className="py-10 px-3 footer shadow bottom-0 w-full">
    <div className="container mx-auto">
      <div>
        <div className="px-3 mb-8 text-center">
          <svg className="logo-ducati mx-auto" viewBox="0 0 136 26">
            <use xlinkHref="#logo-ducati">
            <symbol viewBox="0 0 136 26" id="logo-ducati" xmlns="http://www.w3.org/2000/svg"><path d="M11.7 19.458h1c2.7-.1 3.7-.9 4.4-3.5.4-1.6.8-3.2 1.1-4.8.1-.7.1-1.5.1-2.3-.1-.9-.6-1.6-1.4-1.9-.8-.3-1.6-.4-2.5-.3-1 4.2-1.8 8.4-2.7 12.8M6.3.958h10.9c1.9 0 3.7.3 5.4.9 2.9 1 4.5 3.2 4.7 6.2.4 4.6-1 8.8-4 12.4-1.9 2.2-4.3 3.4-7.1 4-1.4.3-2.9.4-4.3.5-3.3.1-6.6 0-9.9 0h-.7c1.7-8.1 3.4-16 5-24M41.278.958c-.2.8-.3 1.5-.4 2.2-.9 4.3-1.8 8.7-2.7 13-.1.5-.1 1-.1 1.5.1 1.3 1.3 1.8 2.4 1.1.5-.3.8-.8 1-1.4.3-1 .6-2 .8-3.1l2.7-12.9c0-.1.1-.3.1-.5h9.1v.3c-1.1 5.1-2.1 10.1-3.2 15.2 0 .2-.1.4-.2.6-1.3 4.6-4.3 7.3-9 8.2-2.7.5-5.5.4-8.3-.2h-.2c-3-.8-4.6-2.8-4.8-5.9-.1-1.6.1-3.2.5-4.8.9-4.2 1.7-8.3 2.6-12.5.1-.3.1-.5.2-.8h9.5zM91.2 6.6c-.1 0-.1 0-.2-.1-1.3 2.9-2.6 5.7-3.9 8.7h4.1V6.6zm-7.9 18.3h-9.1c.1-.3.2-.6.4-.8 3.9-7.5 7.9-14.9 11.8-22.4.3-.6.6-.8 1.3-.8H98.9c.8 8 1.5 16 2.3 24h-9.4c-.1-1.2-.3-2.3-.4-3.6h-6.6c-.6 1.1-1 2.3-1.5 3.6zM67.5 16.2h8.8c-.1.3-.1.5-.2.7-.5 1-.9 2-1.4 3-1.9 3.3-4.9 5-8.6 5.5-1.9.3-3.8.3-5.6-.1-3.6-.8-5.9-2.9-6.5-6.5-1-6.2.9-11.5 5.8-15.6C62 1.3 64.7.5 67.7.4c1.7-.1 3.4.1 5 .5 3.2.9 4.9 3 5 6.3v2.4c0 .1 0 .3-.1.4h-8.3c-.1-.7 0-1.3-.2-1.9-.3-1.2-1.3-1.6-2.4-1.1-.8.4-1.5 1.1-1.9 2-1.1 2.4-1.8 5-1.8 7.6 0 .5.1 1 .2 1.4.2.7.6 1.3 1.4 1.4.8.1 1.6-.1 2-.9.3-.4.4-.9.6-1.4.1-.2.2-.5.3-.9M124.1.9c-.5 2.1-.9 4.1-1.4 6.3h-6c-1.2 5.9-2.4 11.7-3.6 17.6h-9c1.2-5.8 2.3-11.7 3.5-17.6h-5.9c.5-2.2.9-4.2 1.4-6.3h21zM125.962.958h8.7c.1.6-4.3 22-4.9 23.9h-8.8c1.7-8 3.3-15.9 5-23.9"></path></symbol>
            </use>
          </svg>
        </div>
        <div className="mb-8">
          <div className="text-center text-red-ci font-bold p-2">Öffnungszeiten</div>
          <div>
            <table className="text-gray-100 mx-auto">
              <tbody>
              {strapiCompanyData.openingHours.map(opening => (
                  <tr><th className="uppercase text-gray-ci pr-4 pb-2 text-center font-bold">{opening.day}</th><td className="pb-2">{opening.hoursEarly}</td><td className="pb-2">{opening.hoursLate}</td></tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="px-3 mb-8 text-center md:w-2/3 xl:w-1/2 mx-auto sm:flex sm:justify-between uppercase">
          <Link className="block sm:inline mb-2" to="/">Startseite</Link><Link className="block sm:inline mb-2" to="/datenschutz">Datenschutz</Link><Link className="block sm:inline mb-2" to="/impressum">Impressum</Link>
        </div>
        <div className="px-3 text-center">
          Copyright © {new Date().getFullYear()} {strapiCompanyData.name}. Alle Rechte vorbehalten.
        </div>
      </div>
    </div>
  </footer>
)};

const query = graphql`
query Footer {
  strapiCompanyData {
    name,
    openingHours {
      day,
      hoursEarly,
      hoursLate
    }
  }
}
`

export default Footer;
